<template>
    <vue2CommentsComponent
        bodySelector=".task_body_wrap"
        :related_object="task.id"
        @added="getCommentsCount"
        model="tasks" />
</template>

<script>
import vue2CommentsComponent from '@apps/vue2CommentsComponent'
export default {
    props: {
        task: {
            type: Object,
            required: true
        },
        getCommentsCount: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        vue2CommentsComponent
    }
}
</script>